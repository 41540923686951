.login-headline h1 {
  color: #5a5a5a;
  margin-top: 1.875rem;
  font-size: 2.5rem;
}
.login-headline h2 {
  color: #5a5a5a;
  font-weight: 200;
  margin-bottom: 1.5625rem;
  font-size: 2rem;
}
.has-special-corners {
  position: relative;
}
.has-special-corners:after,
.has-special-corners:before {
  content: "";
  display: block;
  height: 2.5rem;
  position: absolute;
  width: 8.75rem;
}
.has-special-corners:before {
  border-left: 3px solid #5a5a5a;
  border-top: 3px solid #5a5a5a;
  left: 0.3125rem;
  top: 0.3125rem;
}
.has-special-corners:after {
  border-bottom: 3px solid #5a5a5a;
  border-right: 3px solid #5a5a5a;
  bottom: 0.3125rem;
  right: 0.3125rem;
}

.login-btn:before,
.login-btn:after {
  content: "";
  display: block;
  height: 1.25rem;
  position: absolute;
  width: 1.25rem;
}
.login-btn:before {
  border-left: 1px solid #5a5a5a;
  border-top: 1px solid #5a5a5a;
  left: 0.3125rem;
  top: 0.3125rem;
}
.login-btn:after {
  border-bottom: 1px solid #5a5a5a;
  border-right: 1px solid #5a5a5a;
  bottom: 0.3125rem;
  right: 0.3125rem;
}
.login-btn {
  background: none;
  border: none;
  border-radius: 0;
  color: #5a5a5a;
  font-weight: 700;
  padding: 10px 20px;
  position: relative;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}
